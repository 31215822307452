@font-face {
  font-family: "helveticaregular";
  src: url("../fonts/helvetica-webfont.woff2") format("woff2"),
    url("../fonts/helvetica-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticabold";
  src: url("../fonts/helvetica-bold-webfont.woff2") format("woff2"),
    url("../fonts/helvetica-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticabold_oblique";
  src: url("../fonts/helvetica-boldoblique-webfont.woff2") format("woff2"),
    url("../fonts/helvetica-boldoblique-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticacompressed";
  src: url("../fonts/helvetica-compressed-5871d14b6903a-webfont.woff2") format("woff2"),
    url("../fonts/helvetica-compressed-5871d14b6903a-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticaoblique";
  src: url("../fonts/helvetica-oblique-webfont.woff2") format("woff2"),
    url("../fonts/helvetica-oblique-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticarounded_bold";
  src: url("../fonts/helvetica-rounded-bold-5871d05ead8de-webfont.woff2") format("woff2"),
    url("../fonts/helvetica-rounded-bold-5871d05ead8de-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica 55 Roman";
  src: url("../fonts/HelveticaNeue-Roman.woff2") format("woff2"),
    url("../fonts/HelveticaNeue-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

a:hover {
  text-decoration: none;
}

:root {
  --color-dark-blue: #2f2483;
  --color-pink: #db1690;
  --color-white: #ffffff;
  --color-orange: #ee7427;
  --color-gray: #757676;
  --color-light-gray: #f8f8f8;
  --color-black: #191a1a;
  --color-purple: #603085;
  --font-helvatica: "helveticaregular";
}

body {
  font-family: "helveticaregular";
  color: #848484;
  background: #fff;
  font-size: 16px;
  font-weight: normal;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100vh;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-family: "helveticaregular", sans-serif;
}

h1 {
  color: #a7a7a7;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  letter-spacing: 3px;
  margin-bottom: 30px;
}

h2 {
  color: var(--color-orange);
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

h3 {
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: var(--color-purple);
  text-transform: capitalize;
}

h4 {
  color: var(--color-orange);
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  padding-bottom: 40px;
}

h5 {
  color: #757676;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

p {
  color: #757676;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

strong {
  font-weight: 600;
  color: #222;
}

ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;
}

a {
  color: #ed3237;
  text-decoration: none;
  transition: all 0.5s ease;
}

a:hover {
  color: #141f80;
  text-decoration: none;
  text-decoration: none;
}

* {
  font-family: "helveticaregular", sans-serif;
  outline: none !important;
}

.pe-80 {
  padding-right: 80px;
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: block;
  background: #ed3237;
  color: #fff;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  right: -50px;
  bottom: 15px;
  opacity: 0;
  visibility: hidden;
  transition: background 0.5s;
  z-index: 11;
  transition: 0.5s ease;
}

.back-to-top:hover {
  background: #194b98;
  color: #fff;
}

.back-to-top.scrollfixed {
  right: 15px;
  opacity: 1;
  visibility: visible;
  transition: 0.5s ease;
}

.back-to-top i {
  padding-top: 12px;
}

/* Prelaoder */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.95);
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #ccc;
  border-top: 6px solid #194b98;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sticky-table-cell {
  font-size: 14px;
  padding: 5px 10px;
  vertical-align: middle;
  color: var(--color-black);
  white-space: normal;
  text-align: left;
}

.table-header .sticky-table-cell {
  white-space: nowrap;
  background: var(--color-purple);
  color: var(--color-white);
  white-space: nowrap;
  padding: 10px;
  font-weight: 500;
}

.sticky-table-table,
.sticky-table {
  width: 100%;
}

.sticky-table-cell:last-child {
  width: 100px;
  text-align: right;
}

.services-table {
  padding: 15px;
  background: #FFF;
  overflow-x: auto;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

.services-table table thead tr th {
  background: var(--color-purple);
  color: var(--color-white);
  white-space: nowrap;
  padding: 15px;
  font-weight: 500;
}

.services-table table tbody tr td {
  padding: 5px 10px;
  font-size: 14px;
  vertical-align: middle;
}

.services-table table thead tr th.text-right,
.services-table table tbody tr td.text-right,
.text-right {
  text-align: right;
}

.services-table table tbody tr:nth-of-type(odd)>* {
  background: #fcf8ff;
}

.text-red {
  color: red;
}

.services-table table tbody tr td button.delete-btn {
  padding: 0;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: var(--color-white);
  background: rgb(182, 2, 2);
  font-size: 18px;
  font-weight: 700;
}

.pad-20 {
  padding: 20px;
}

.pad-top-10 {
  padding-top: 10px;
}

.pad-top-20 {
  padding-top: 20px;
}

.pad-top-30 {
  padding-top: 30px;
}

.pad-top-50 {
  padding-top: 50px !important;
}

.pad-top-70 {
  padding-top: 70px !important;
}

.pad-top-100 {
  padding-top: 100px !important;
}

.pad-bot-20 {
  padding-bottom: 20px !important;
}

.pad-bot-30 {
  padding-bottom: 30px !important;
}

.pad-bot-50 {
  padding-bottom: 50px !important;
}

.pad-bot-100 {
  padding-bottom: 100px !important;
}

.mar-bot-10 {
  margin-bottom: 10px !important;
}

.mar-bot-20 {
  margin-bottom: 20px !important;
}

.mar-bot-30 {
  margin-bottom: 30px !important;
}

.mar-bot-50 {
  margin-bottom: 50px !important;
}

.mar-top-20 {
  margin-top: 20px !important;
}

.mar-top-30 {
  margin-top: 30px !important;
}

.mar-top-50 {
  margin-top: 50px !important;
}

.gray-bg-light {
  background: rgba(0, 0, 0, 0.03);
}

.bg-light-orange {
  background: rgba(238, 116, 39, 0.12);
}

.bg-orange {
  background: var(--color-orange);
}

.bg-purple {
  background: var(--color-purple);
}

.text-orange {
  color: var(--color-orange);
}

.orange-text {
  white-space: nowrap;
}

.text-purple {
  color: var(--color-purple) !important;
}

section {
  padding: 100px 0px;
}

section.home-slider-bg {
  padding: 0px;
}

section.home-slider-bg .carousel .carousel-item {
  opacity: 0;
  transition: all 0.6s ease;
}

section.home-slider-bg .carousel-fade .carousel-item-next.carousel-item-start,
section.home-slider-bg .carousel-fade .carousel-item-prev.carousel-item-end,
section.home-slider-bg .carousel-fade .carousel-item.active {
  opacity: 1;
}

section.home-slider-bg .carousel .carousel-item.carousel-item-start,
section.home-slider-bg .carousel .carousel-item.carousel-item-end {
  opacity: 0 !important;
  transition: all 0s ease;
}

section.home-slider-bg .carousel .carousel-item.active {
  opacity: 1;
  transition: all 0.6s ease;
}

section.home-slider-bg .carousel .carousel-item .carousel-caption {
  left: inherit;
  top: 40%;
  right: inherit;
  bottom: inherit;
  text-align: left;
  padding: 0px;
  max-width: 600px;
  opacity: 0;
  transition: all 1s ease;
}

section.home-slider-bg .carousel .carousel-item.active .carousel-caption {
  opacity: 1;
  top: 20%;
  transition: all 1s ease;
}

section.home-slider-bg .carousel .carousel-item .carousel-caption h3 {
  font-size: 45px;
  color: var(--color-purple);
  font-weight: 600;
  line-height: 63px;
  text-transform: none;
  letter-spacing: -1px;
  padding-left: 0px;
  margin-bottom: 0px;
  text-align: left;
}

section.home-slider-bg .carousel .carousel-item .carousel-caption h3 span {
  font-weight: 300;
}

section.home-slider-bg .carousel .carousel-item .carousel-caption h3 strong {
  font-weight: 500;
  color: var(--color-orange);
  text-transform: uppercase;
}

section.home-slider-bg .carousel .carousel-item .carousel-caption h3 strong span {
  font-weight: 700;
  color: var(--color-purple);
}

section.home-slider-bg .carousel .carousel-control-next,
section.home-slider-bg .carousel .carousel-control-prev {
  width: 50px;
  height: 50px;
  top: 40%;
  opacity: 1;
}

section.home-slider-bg .carousel .carousel-control-next {
  right: -100px;
}

section.home-slider-bg .carousel .carousel-control-prev {
  left: -100px;
}

section.home-slider-bg .carousel .carousel-control-next:before {
  background: url(assets/images/arrow.png) center center no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  transform: rotateY(180deg);
}

section.home-slider-bg .carousel .carousel-control-prev:before {
  background: url(assets/images/arrow.png) center center no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
}

section.home-slider-bg .carousel .carousel-control-next .carousel-control-next-icon {
  display: none;
}

section.home-slider-bg .carousel .carousel-indicators button {
  background: var(--color-orange);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: none;
  opacity: 1;
  transition: all 0.3s ease;
}

section.home-slider-bg .carousel .carousel-indicators button.active {
  background: var(--color-purple);
  width: 40px;
  transition: all 0.3s ease;
}

.orange-btn {
  color: var(--color-white);
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  display: inline-block;
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 19px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  z-index: 10;
  transition: all 0.3s ease;
}

.orange-btn:hover {
  color: var(--color-white);
  transition: all 0.3s ease;
}

.orange-btn.hover-white:hover {
  color: var(--color-orange);
}

.orange-btn:before {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background: var(--color-orange);
}

.orange-btn:after {
  position: absolute;
  right: 0;
  top: 0px;
  width: 0px;
  height: 100%;
  content: "";
  z-index: -1;
  background: var(--color-purple);
  transition: all 1s ease;
}

.orange-btn.hover-white:after {
  background: var(--color-white);
}

.orange-btn:hover:after {
  width: 100%;
  right: inherit;
  left: 0px;
  transition: all 1s ease;
}

.purple-btn {
  color: var(--color-white);
  padding: 15px 30px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 19px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.purple-btn:hover {
  color: var(--color-white);
  transition: all 0.3s ease;
}

.purple-btn:before {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -2;
  background: var(--color-purple);
}

.purple-btn:after {
  position: absolute;
  right: 0;
  top: 0px;
  width: 0px;
  height: 100%;
  content: "";
  z-index: -1;
  background: var(--color-orange);
  transition: all 1s ease;
}

.purple-btn:hover:after {
  width: 100%;
  right: inherit;
  left: 0px;
  transition: all 1s ease;
}

.why-us ul {
  display: flex;
  padding: 0;
}

.why-us ul li {
  list-style: none;
  width: 20%;
  padding: 0 10px;
  margin-bottom: 30px;
  margin-top: 10px;
  transition: all 0.3s ease;
}

.why-us ul li .why-choose-icons {
  background: url(assets/images/iconbg.png) no-repeat;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  top: 20px;
  transition: all 0.3s ease;
}

.why-us ul li:hover .why-choose-icons {
  top: 40px;
  transition: all 0.3s ease;
}

.why-us ul li .why-choose-icons img {
  margin: 0 auto;
}

.why-us ul li .why-choose-text {
  background: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 20px 30px;
  padding: 30px 15px 15px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.why-us ul li:hover .why-choose-text {
  background: var(--color-orange);
  transition: all 0.3s ease;
}

.why-us ul li .inner-box {
  min-height: 120px;
  display: flex;
  align-items: center;
}

.why-us ul li .why-choose-text p {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: var(--color-gray);
  margin-bottom: 0px;
  transition: all 0.3s ease;
}

.why-us ul li:hover .why-choose-text p {
  color: var(--color-white);
  transition: all 0.3s ease;
}

.package-slide {
  background: var(--color-white);
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 10px;
  padding: 30px 20px;
  border-radius: 5px;
  position: relative;
  display: block;
  margin: 30px 10px;
}

.package-slide.small-size {
  padding: 30px 20px 10px 20px;
}

.package-slide:before {
  content: "";
  background: url(assets/images/packtop.png) no-repeat;
  width: 75px;
  height: 63px;
  position: absolute;
  top: 0px;
  right: -2px;
  background-color: #fdeee4;
  z-index: 5;
}

.package-slide h5 {
  font-weight: 600;
  font-size: 24px;
  position: relative;
  z-index: 10;
  color: var(--color-orange);
}

.package-slide h4 {
  font-weight: 600;
  font-size: 17px;
  min-height: 90px;
  line-height: 24px;
  padding-right: 30px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  color: var(--color-purple);
}

.package-slide h5 span {
  display: block;
  color: #333;
  font-size: 18px;
  padding-top: 5px;
  font-weight: 500;
}

.package-slide .location {
  min-height: 80px;
}

.package-slide .location,
.package-slide .working-hours,
.package-slide .experience {
  padding: 0px 0px 10px 38px;
  position: relative;
  font-size: 14px;
  color: var(--color-black);
}

.apply-now-icons {
  padding: 0px 0px 20px 45px;
  position: relative;
  font-size: 17px;
  color: var(--color-black);
}

.apply-now-icons svg {
  position: absolute;
  left: 5px;
  top: 0px;
  font-size: 24px;
  color: var(--color-purple);
}

.package-slide .phone {
  padding: 5px 0px 10px 38px;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0px;
  color: var(--color-black);
}

.package-slide .location svg,
.package-slide .working-hours svg {
  position: absolute;
  left: 5px;
  top: 2px;
  color: var(--color-purple);
}

.package-slide .experience svg {
  position: absolute;
  left: 5px;
  top: 0px;
  font-size: 20px;
  color: var(--color-purple);
}

.package-slide .phone svg {
  position: absolute;
  left: 5px;
  top: 10px;
  color: var(--color-purple);
}

.package-slide .location svg,
.package-slide .working-hours svg {
  font-size: 22px;
}

.package-slide .phone svg {
  font-size: 18px;
}

.package-slide .location svg path {
  stroke: var(--color-purple);
}

.package-slide .orange-btn {
  padding: 10px 20px;
  margin-top: 10px;
}

.package-slide .orange-btn svg {
  font-size: 24px;
  margin-right: 10px;
}

.package-slide .icon-img {
  padding-bottom: 20px;
}

.package-slide .icon-img img {
  width: 100px;
  height: 100px;
}

.package-slide h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: left;
  height: 50px;
  line-height: 25px;
  color: var(--color-orange);
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.package-slide h3 span {
  display: block;
  font-size: 14px;
  color: var(--color-black);
  font-weight: 300;
}

.package-slide.news-content {
  margin-bottom: 40px;
}

.package-slide.news-content a {
  padding: 0px 40px 0px 80px;
}

.package-slide.news-content h3 {
  height: auto;
  margin-bottom: 10px;
  color: var(--color-purple);
  font-size: 20px;
}

.package-slide.news-content .date {
  position: absolute;
  left: 0px;
  top: -60px;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  border-radius: 7px;
  color: var(--color-white);
  box-shadow: -5px 5px 0px rgba(238, 116, 39, 0.5);
}

.package-slide.news-content .date:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -10;
  border-radius: 7px;
  background: var(--color-orange);
  clip-path: polygon(0 0, 85% 0, 100% 20%, 100% 100%, 0 100%, 0 100%);
}

.package-slide.news-content .date strong {
  display: block;
  font-size: 30px;
  color: var(--color-white);
  line-height: 36px;
}

.package-slide.small-size.news-content .booklet-download span {
  bottom: -10px;
}

.package-slide.small-size.news-content:hover .booklet-download span {
  bottom: -20px;
}

.package-slide .inc-test {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: var(--color-gray);
  padding-bottom: 20px;
  display: flex;
}

.package-slide .inc-test img {
  margin-right: 10px;
}

.package-slide p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-gray);
  height: 35px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.package-slide.news-content p {
  height: 55px;
  -webkit-line-clamp: 3;
}

.package-slide .pckge_price {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  color: var(--color-purple);
}

.package-slide .pckge_price span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  padding-right: 10px;
  text-decoration-line: line-through;
  color: var(--color-gray);
}

.package-slide .viewbtn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(241, 90, 41, 0.25);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  right: 20px;
  bottom: 25px;
  z-index: 999;
  transition: all 0.5s ease;
}

.package-slide:hover .viewbtn {
  right: 10px;
  background: var(--color-orange);
  transition: all 0.5s ease;
}

.package-slides .slick-next,
.package-slides .slick-prev {
  width: 50px;
  height: 50px;
  z-index: 999;
}

.package-slides .slick-next {
  right: -50px;
}

.contact-form span {
  position: absolute;
  margin-top: -20px;
  font-size: 13px;
}

.package-slides .slick-next::before {
  content: "";
  width: 50px;
  height: 50px;
  opacity: 1;
  display: block;
  background: url(assets/images/arroe-blue.png) center center no-repeat;
}

.package-slides .slick-prev {
  left: -50px;
}

.package-slides .slick-prev::before {
  content: "";
  width: 50px;
  height: 50px;
  opacity: 1;
  display: block;
  transform: rotateY(180deg);
  background: url(assets/images/arroe-blue.png) center center no-repeat;
}

.list-style1 {
  margin: 0;
  padding: 0;
}

.list-style1 li {
  padding: 0px 0px 15px 30px;
  position: relative;
  color: var(--color-black);
  list-style: none;
}

.list-style1 li:before {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: 0px;
  top: 9px;
  background: var(--color-orange);
  content: "";
}

.list-style1 li:after {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: 6px;
  top: 6px;
  background: var(--color-purple);
  content: "";
}

.bg-dot {
  position: relative;
  padding-top: 70px;
}

.bg-dot:before {
  content: "";
  width: 100%;
  min-height: 450px;
  background: url(assets/images/curve.png) no-repeat center center;
  background-position: center center;
  position: absolute;
}

.img-round,
.img-div .img-round {
  border-radius: 50% !important;
}

.center-img {
  position: relative;
  width: 180px;
  margin: 0 auto;
}

.center-img .number-img {
  margin: 0;
  padding: 0;
  position: absolute;
  top: -15px;
  left: -46px;
  z-index: 0;
}

.animate-lef-rig {
  animation: run 2s linear infinite;
}

@keyframes run {
  0% {
    left: -10px;
  }

  50% {
    left: -40px;
  }

  100% {
    left: -10px;
  }
}

.animate-top-bot {
  animation: run1 2s linear infinite;
}

@keyframes run1 {
  0% {
    top: 0px;
  }

  50% {
    top: 40px;
  }

  100% {
    top: 0px;
  }
}

.animate-rotate {
  animation: rot 2s linear infinite alternate;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.sm-center-img {
  position: relative;
  max-width: 200px;
  margin: 0 auto;
}

.number-img {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0px;
  left: -30px;
  z-index: 0;
}

.img-div {
  padding-bottom: 25px;
}

.img-div .img-round {
  position: relative;
  z-index: 1;
}

.text-div h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: var(--color-orange);
  padding-bottom: 10px;
}

.text-div p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--color-gray);
}

.home-pcr-collection {
  background: var(--color-purple);
  position: relative;
  min-height: 530px;
}

.virus-bg {
  background: url(assets/images/virusbg.png) left -30px bottom no-repeat;
  background-size: 100%;
}

.home-pcr-collection:after {
  content: "";
  position: absolute;
  background: url(assets/images/home-pcr-collection.jpg) no-repeat;
  background-size: cover;
  width: 50%;
  height: 530px;
  right: 0;
  top: 0;
  z-index: 1;
}

.home-pcr-collection .container {
  z-index: 10;
  position: relative;
}

.dna-bg {
  background: url(assets/images/dna.png) right top no-repeat;
}

.dna-bg p {
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  padding-top: 30px;
}

.video-image {
  position: relative;
}

.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#fa183d, 10%);
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--color-purple);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.dna-bg .exp {
  left: -6px;
  bottom: -50px;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  z-index: 10;
  padding: 14px;
}

.dna-bg .exp:before {
  content: "";
  clip-path: polygon(0 1%, 68% 0, 100% 30%, 99% 100%, 41% 99%, 0 100%);
  background: #ee7427;
  position: absolute;
  width: 150px;
  height: 145px;
  padding: 20px;
  border-radius: 5px;
  z-index: 2;
  left: 0;
  bottom: 0;
}

.dna-bg .exp:after {
  clip-path: polygon(0 1%, 68% 0, 100% 30%, 99% 100%, 41% 99%, 0 100%);
  background: #fdeee5;
  content: "";
  position: absolute;
  width: 150px;
  height: 145px;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  z-index: -1;
  left: 10px;
  bottom: -10px;
}

.dna-bg .exp h2 {
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 57px;
  color: #ffffff;
  position: relative;
  z-index: 20;
}

.dna-bg .exp p {
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  position: relative;
  z-index: 20;
  padding-top: 0px;
}

.expertise-txt {
  position: relative;
  background: var(--color-white);
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 6px 7px 15px -9px rgb(0 0 0 / 75%);
  -moz-box-shadow: 6px 7px 15px -9px rgb(0 0 0 / 75%);
  box-shadow: 6px 7px 15px -9px rgb(0 0 0 / 75%);
}

.expertise-txt img {
  width: 100%;
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  overflow: hidden;
  -webkit-transition: all 850ms ease-in-out;
  -moz-transition: all 850ms ease-in-out;
  transition: all 850ms ease-in-out;
}

.expertise-txt:hover img {
  -webkit-transform: scale3d(1.03, 1.03, 1.03) !important;
  -moz-transform: scale3d(1.03, 1.03, 1.03) !important;
  -ms-transform: scale3d(1.03, 1.03, 1.03) !important;
  transform: scale3d(1.03, 1.03, 1.03) !important;
}

.expertise_inner {
  overflow: hidden;
  min-height: 284px;
  border-radius: 10px;
}

.exper-txt {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 15px 15px 15px;
  bottom: -5px;
  right: 0px;
  clip-path: polygon(0 0%, 85% 0, 100% 40%, 100% 100%, 50% 100%, 0 100%);
  border-radius: 0px 10px 10px 10px;
  z-index: 20;
}

.exper-txt img {
  display: inline-block;
}

.exper-txt h3 {
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #603085;
  padding-top: 5px;
}

.exper-txt p {
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #727271;
  margin-bottom: 5px;
}

.exper-txt .readmore {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #f15a29;
  font-family: "helveticaregular";
}

.expertise-txt .exper-txt .readmore svg {
  font-size: 20px;
  margin-left: 5px;
  transition: all 0.3s ease;
}

.expertise-txt:hover .exper-txt .readmore svg {
  transition: all 0.3s ease;
  margin-left: 10px;
}

.expertise .slick-slide>div {
  padding: 15px;
}

.find-center {
  background: var(--color-purple);
  position: relative;
  width: 100%;
  min-height: 400px;
  overflow: hidden;
}

.find-center:after {
  content: "";
  position: absolute;
  background: url(assets/images/find-center.jpg) no-repeat;
  background-size: cover;
  width: 35%;
  height: 450px;
  right: 0;
  top: 0;
  z-index: 1;
}

.findnearest h3 {
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  color: var(--color-white);
}

.findnearest h3 span {
  font-weight: 300;
  text-transform: uppercase;
  color: var(--color-orange);
  font-family: "helveticaregular";
}

.findnearest h3 span b {
  font-weight: 700;
  color: #fff;
}

.findnearest p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-white);
}

.testimonials {
  margin-bottom: 50px;
}

.package-slides.testimonials .slick-slide>div {
  padding: 0px 10px;
}

.testimonials-list {
  padding: 20px 20px 100px 20px;
  background: #fff;
  min-height: 300px;
  position: relative;
  margin: 20px 0px;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
}

.testimonials-list:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 0px;
  background: #ffffff;
  opacity: 0.5;
  content: "";
  left: 0;
  top: 0;
}

.slick-current .testimonials-list:before {
  opacity: 0;
}

.testimonials-list p {
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.quotes-icon {
  position: absolute;
  top: -15px;
  left: 60px;
}

.user-details {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 20px);
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.user-details h3 {
  font-family: "helveticaregular";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding-left: 20px;
  display: inline-block;
}

.user-details img {
  display: inline-block;
  max-width: 80px;
}

.slick-dots li {
  width: auto;
  height: auto;
}

.slick-dots li button {
  background: var(--color-purple);
  opacity: 1;
  padding: 0px;
  width: 10px;
  height: 10px;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.slick-dots li.slick-active button {
  width: 30px;
  background: var(--color-orange);
  transition: all 0.3s ease;
}

.slick-dots li button:before {
  display: none;
}

.inner-banner {
  background: url(assets/images/inner-banner-1.jpg) center center no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  color: #ffffff;
}

.inner-banner.about-us {
  background: url(assets/images/about-banner.jpg) center center no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.about-founder {
  background: url(assets/images/founder-banner.jpg) center right no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.team {
  background: url(assets/images/team-banner.jpg) center center no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.home-visit {
  background: url(assets/images/home-visit-banner.jpg) center center no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.awards {
  background: url(assets/images/awards-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.csr-policy {
  background: url(assets/images/csr-policy-banner.jpg) right top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.packages {
  background: url(assets/images/package-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.find-a-lab {
  background: url(assets/images/find-a-lab-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.booklet {
  background: url(assets/images/packages-booklet-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.technical-leaflets {
  background: url(assets/images/technical-leaflets-booklets-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.terms-conditions {
  background: url(assets/images/terms-conditions-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.privacy-policy {
  background: url(assets/images/privacy-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.disclaimer {
  background: url(assets/images/disclaimer-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.career {
  background: url(assets/images/career-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.current-openings {
  background: url(assets/images/current-openings-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.apply-now {
  background: url(assets/images/current-openings-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.contact {
  background: url(assets/images/contact-banner.jpg) right top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.covid-pcr {
  background: url(assets/images/covid-pcr-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.specialities {
  background: url(assets/images/specialities-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.media {
  background: url(assets/images/media-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.inner-banner.news {
  background: url(assets/images/news-banner.jpg) center top no-repeat var(--color-black);
  background-size: cover;
}

.career-bg {
  background: url(assets/images/career-image.jpg) left -100px top no-repeat var(--color-white);
  background-size: 80%;
}

.current-openings-cta {
  background: url(assets/images/current-openings-bg.jpg) left top no-repeat fixed var(--color-black);
  background-size: cover;
  position: relative;
  margin-top: 100px;
}

.current-openings-cta::before {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  content: "";
  width: 100%;
  height: 100%;
  z-index: 0;
}

.current-openings-cta .container {
  z-index: 10;
  position: relative;
}

.career-content {
  padding: 70px;
  background: rgba(238, 116, 39, 0.95);
  margin-top: 20%;
  margin-bottom: -100px;
  margin-right: -100px;
}

.current-openings-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px 0px;
}

.career-content h3,
.career-content p {
  color: var(--color-white);
}

.package-details:before {
  content: "";
  background: url(assets/images/packageright.png) no-repeat #603085;
  background-size: cover;
  width: 40%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  height: 100%;
}

.apply-now-form:before {
  content: "";
  background: url(assets/images/packageright.png) no-repeat #603085;
  background-size: cover;
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  height: 100%;
}

.nav-tabs {
  justify-content: center;
  border-bottom: none;
  margin-bottom: 30px;
}

.nav-tabs .nav-link {
  border: none;
  border-radius: 0px;
  color: var(--color-gray);
  padding: 10px 30px;
  font-size: 24px;
  font-weight: 500;
  background: transparent;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.nav-tabs .nav-link.active {
  color: var(--color-orange);
  border: none;
  background: transparent;
  border-bottom: 2px solid var(--color-orange);
}

.package-details-heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.package-details-heading img {
  width: 80px;
  height: 80px;
}

.package-details-heading h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  padding-left: 30px;
  line-height: 40px;
  text-align: left;
  color: var(--color-purple);
  padding-bottom: 0px;
}

.pckge_include {
  padding-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.pckge_include img {
  margin-right: 20px;
}

.pckge_include h5 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: var(--color-gray);
  margin-bottom: 10px;
}

.pckge_include p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-black);
}

.pckge_details ul {
  padding: 0;
  margin: 0;
}

.pckge_details ul li {
  list-style: none;
  margin-left: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.apply-now-form-bg {
  background: #fdeee5;
  border-radius: 7px;
  padding: 30px;
  margin: 10px;
  z-index: 10;
  box-shadow: 15px 15px 0px var(--color-orange);
}

.pckge_price_details {
  background: #fdeee5;
  border-radius: 7px;
  padding: 50px;
  position: sticky;
  top: 70px;
  margin: 10px;
  z-index: 10;
  box-shadow: 15px 15px 0px var(--color-orange);
}

.pckge_price_details h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 28px;
  text-decoration-line: line-through;
  color: var(--color-gray);
  padding-bottom: 20px;
  position: relative;
  z-index: 20;
}

.pckge_price_details h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  color: var(--color-purple);
  padding-bottom: 25px;
  position: relative;
  z-index: 20;
}

.btn1 {
  color: var(--color-white);
  padding: 15px 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 19px;
  font-weight: 500;
  overflow: hidden;
  transition: all 0.3s ease;
  background: var(--color-purple);
  border: 2px solid var(--color-purple);
  border-radius: 4px;
  position: relative;
  z-index: 20;
}

.btn1:hover {
  color: var(--color-purple);
  background: var(--color-white);
  border: 2px solid var(--color-purple);
  transition: all 0.3s ease;
}

.btn2 {
  color: var(--color-purple);
  padding: 15px 30px;
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 19px;
  font-weight: 500;
  overflow: hidden;
  transition: all 0.3s ease;
  background: var(--color-white);
  border: 2px solid var(--color-purple);
  border-radius: 4px;
  position: relative;
  z-index: 20;
}

.btn2:hover {
  color: var(--color-white);
  background: var(--color-purple);
  transition: all 0.3s ease;
}

.about-image {
  text-align: center;
  background: var(--color-white);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 15px 15px 0px var(--color-orange);
  margin-bottom: 40px;
}

.sticky-img {
  position: sticky;
  top: 100px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 30px 0px 50px 0px;
  position: relative;
  background: rgba(0, 0, 0, 0.1);
}

.divider:before {
  position: absolute;
  left: calc(50% - 10px);
  width: 10px;
  height: 10px;
  content: "";
  border-radius: 50%;
  background: var(--color-orange);
  top: -5px;
}

.divider:after {
  position: absolute;
  left: 50%;
  width: 10px;
  height: 10px;
  content: "";
  border-radius: 50%;
  background: var(--color-purple);
  top: -5px;
}

.our-values {
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  background: var(--color-black);
}

.our-values .our-values-content {
  position: absolute;
  top: 76%;
  left: 5%;
  width: 88%;
  height: 85%;
  background: var(--color-white);
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px var(--color-orange);
  transition: all 0.6s ease;
}

.our-values:hover .our-values-content {
  top: 5%;
  transition: all 0.6s ease;
}

.our-values .our-values-content h3 {
  text-align: left;
  font-size: 24px;
  margin-bottom: 20px;
  transition: all 0.6s ease;
}

.our-values .our-values-content h3 svg {
  transform: rotate(0deg);
  transition: all 0.6s ease;
}

.our-values:hover .our-values-content h3 svg {
  transform: rotate(90deg);
  transition: all 0.6s ease;
}

.our-values .our-values-content p {
  text-align: left;
  margin-bottom: 0px;
  transition: all 0.6s ease;
}

.our-values img {
  width: 100%;
  transition: all 0.6s ease;
  border-radius: 10px;
}

.our-values:hover img {
  transition: all 0.6s ease;
  opacity: 0.5;
}

input.form-control,
select.form-select {
  height: 50px;
  line-height: 50px;
  padding: 0px 10px;
  border-radius: 5px;
  outline: none !important;
  box-shadow: none !important;
}

select option {
  padding: 5px;
}

textarea.form-control {
  height: 100px;
  line-height: 24px;
  padding: 10px;
  border-radius: 5px;
  resize: none;
  outline: none !important;
  box-shadow: none !important;
}

.booklet-download {
  display: block;
  z-index: 10;
  position: relative;
  transition: all 0.3s ease;
}

.booklet-download img {
  width: 100%;
  transition: all 0.3s ease;
}

.booklet-download:hover img {
  opacity: 0.5;
  transition: all 0.3s ease;
}

.booklet-download span {
  position: absolute;
  right: -10px;
  bottom: -20px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  color: var(--color-white);
  background: var(--color-orange);
  transition: all 0.3s ease;
}

.package-slide.small-size .booklet-download span {
  width: 35px;
  height: 35px;
  font-size: 18px;
  bottom: 5px;
}

.package-slide.small-size .booklet-download:hover span {
  bottom: 15px;
}

.booklet-download:hover span {
  bottom: -40px;
  background: var(--color-purple);
  transition: all 0.3s ease;
}

.Contact-text {
  padding: 20px 20px 20px 20px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Contact-text h4 {
  padding-bottom: 0px;
}

.Contact-text svg {
  font-size: 36px;
  margin-bottom: 10px;
}

.Contact-text.fill-color svg {
  fill: var(--color-purple) !important;
}

.Contact-text svg path {
  stroke: var(--color-purple) !important;
}

.google-map {
  background: var(--color-white);
  width: 100%;
  height: 500px;
  border-top: 5px solid var(--color-orange);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

.accordion-button {
  padding: 20px 20px 20px 45px;
  font-size: 20px;
  border-radius: 10px 10px 0px 0px;
  font-weight: 500;
  border-bottom: none;
  position: relative;
  color: var(--color-black);
  box-shadow: none !important;
  background: transparent !important;
}

.accordion-button:not(.collapsed) {
  color: var(--color-white);
  background: var(--color-orange) !important;
}

.accordion-button:focus {
  border-bottom: none;
}


.accordion-button::after {
  position: absolute;
  left: 15px;
  top: 19px;
  width: 19px;
  height: 19px;
  display: block;
  content: "";
  background: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.accordion-button:not(.collapsed)::after {
  top: 14px;
  left: 10px;
  width: 32px;
  height: 32px;
  border-radius: 0px;
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  background-image: url(../images/checkbox.png);
  transform: none;
  background-repeat: no-repeat;
}

.accordion-item {
  margin-bottom: 20px;
  border-radius: 10px;
  border: none !important;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
}

.accordion-button::before {
  position: absolute;
  right: 10px;
  top: 15px;
  width: 30px;
  height: 30px;
  content: "";
  background: url(assets/images/arroe-blue.png) center center no-repeat;
  background-size: 10px;
  transition: all 0.3s ease;
}

.accordion-button:not(.collapsed)::before {
  transform: rotate(90deg);
  filter: brightness(0) invert(1);
  transition: all 0.3s ease;
}

.accordion-body {
  padding: 20px;
}

.ques {
  position: relative;
  font-size: 16px;
  color: var(--color-black);
  font-weight: 600;
  padding: 3px 0px 15px 40px;
}

.ques:before {
  position: absolute;
  width: 30px;
  height: 30px;
  content: "Q";
  background: var(--color-purple);
  left: 0;
  top: 0;
  font-weight: 500;
  border-radius: 50%;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.answ {
  position: relative;
  font-size: 14px;
  padding: 0px 0px 20px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.answ:before {
  position: absolute;
  width: 25px;
  height: 25px;
  content: "A";
  left: 3px;
  top: 0;
  font-weight: 600;
  font-size: 16px;
  border-radius: 50%;
  color: var(--color-orange);
  border: 1px solid var(--color-orange);
  display: flex;
  align-items: center;
  justify-content: center;
}

.package-details-form {
  background: var(--color-white);
  padding: 60px 10px 20px 10px;
  position: fixed;
  right: -500px;
  opacity: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 1s ease;
  top: 0px;
  width: 400px;
  z-index: 9999;
  border-left: 5px solid var(--color-orange);
  box-shadow: -10px -2px 20px rgba(0, 0, 0, 0.3);
}

.package-details-form.active {
  right: 0px;
  opacity: 1;
  transition: all 1s ease;
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--color-purple);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  color: var(--color-white);
  transition: all 0.6s ease;
}

.btn-close svg path {
  stroke: var(--color-white);
}

.btn-close:hover {
  background: var(--color-orange);
  transform: rotate(90deg);
  transition: all 0.6s ease;
}

.modal .modal-body {
  padding: 20px;
}

.modal-backdrop {
  z-index: 9992;
}

.modal {
  z-index: 9999;
}

.modal .modal-content {
  border: none;
}

.modal .modal-header h4 {
  margin: 0;
  padding: 0;
}

.modal .modal-header h4 small {
  color: var(--color-gray);
  font-size: 16px;
  font-weight: 300;
  display: block;
}

.modal .modal-header .btn-close {
  right: -10px;
  top: -10px;
}

.modal .modal-header .btn-close:before {
  content: "x";
  font-size: 30px;
  width: 50px;
  height: 26px;
  line-height: 20px;
  font-family: inherit;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: var(--color-white);
}

.btn.dropdown-toggle {
  background: #fff;
  padding: 10px 20px;
  font-size: 15px;
  height: 50px;
  border-radius: 5px;
  color: var(--color-black);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0px;
}



.selected-package {
  background: #fff;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 5px;
  color: var(--color-black);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 20px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.selected-package span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-package span strong {
  display: block;
  font-size: 18px;
  padding-top: 5px;
  color: var(--color-purple);
}

.selected-package span strong.text-orange {
  color: var(--color-orange);
}

section.teams {
  padding-bottom: 60px;
}

.teams-card {
  background: var(--color-white);
  padding: 15px 15px 40px 15px;
  border-radius: 10px;
  display: block;
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.teams-card:before {
  content: "+";
  font-size: 26px;
  font-family: "Arial";
  font-weight: 300;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-orange);
  transition: all 0.3s ease;
}

.teams-card:hover:before {
  transition: all 0.3s ease;
  color: var(--color-white);
  background: var(--color-purple);
}

.teams-card:hover {
  box-shadow: 10px 10px 0px var(--color-orange);
  transition: all 0.3s ease;
}

.teams-card h4 {
  font-size: 22px;
  line-height: 30px;
  position: relative;
  padding: 10px 0px;
  margin: 15px 0px 0px 0px;
  color: var(--color-purple);
}

.teams-card h4:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 3px;
  content: "";
  background: var(--color-orange);
}

.teams-card h6 {
  color: var(--color-gray);
  font-size: 14px;
}

.services-search {
  position: relative;
  margin-left: auto;
  display: flex;
  max-width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 50px 5px 15px;
  border-radius: 50px;
  margin-bottom: 15px;
}

.services-search input[type=search] {
  border: none;
  font-size: 15px;
}

.services-search svg {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 20px;
  color: var(--color-orange);
}

.main-lab-address {
  text-align: center;
  padding-bottom: 20px;
}

.main-lab-address h3 {
  font-size: 27px;
}

.main-lab-address h3.text-left {
  text-align: left;
  padding-left: 10px;
}

.main-lab-address small {
  color: var(--color-black);
  font-size: 20px;
  line-height: 40px;
  padding: 10px;
}




@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 98%;
  }
}

@media (max-width: 1500px) {
  section.home-slider-bg .carousel .carousel-control-next {
    right: -50px;
  }

  section.home-slider-bg .carousel .carousel-control-prev {
    left: -50px;
  }

  .career-bg {
    background-position: center top;
  }

  .career-content {
    margin-right: 0px;
  }
}

@media (max-width: 1200px) {

  section.home-slider-bg .carousel .carousel-control-next,
  section.home-slider-bg .carousel .carousel-control-prev {
    display: none;
  }

  .why-us .why-choose-text p {
    font-size: 14px;
    line-height: 20px;
  }

  .package-slides .slick-next {
    right: -20px;
  }

  .package-slides .slick-prev {
    left: -20px;
  }
}

@media (max-width: 992px) {
  section.home-slider-bg .carousel .carousel-item .carousel-caption {
    max-width: 400px;
  }

  section.home-slider-bg .carousel .carousel-item .carousel-caption h3 {
    font-size: 34px;
    line-height: 46px;
  }

  .why-us ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .why-us ul li {
    width: 33.33%;
  }

  .bg-dot:before {
    background-size: 90% auto;
    background-position: center bottom;
  }

  .dna-bg {
    background-size: 200px auto;
  }

  .home-pcr-collection:after,
  .package-details:before {
    display: none;
  }

  .find-center:after,
  .apply-now-form:before {
    display: none;
  }

  .our-values {
    background: transparent;
    box-shadow: none;
    overflow: visible;
  }

  .our-values .our-values-content {
    position: relative;
    left: inherit;
    top: inherit;
    width: 95%;
    margin-top: -30px;
  }

  .our-values:hover .our-values-content {
    top: inherit;
  }

  .our-values:hover img {
    opacity: 1;
  }

  .current-openings-btn {
    justify-content: flex-start;
  }

  .career-content {
    margin-top: 0px;
    padding: 30px;
    background: var(--color-orange);
  }
}

@media (max-width: 767px) {
  section {
    padding: 50px 0px;
  }

  .services-table {
    width: 100%;
    overflow: auto;
  }

  .accordion-button {
    font-size: 18px;
  }

  .services-table table thead tr th,
  .services-table table tbody tr td {
    padding: 5px 10px;
    font-size: 13px;
    line-height: 20px;
  }

  .services-table table.table tr td h5 {
    white-space: nowrap;
    font-size: 16px;
  }

  section.home-slider-bg .carousel .carousel-item .carousel-caption {
    position: relative;
    max-width: 100%;
    text-align: center;
    padding-top: 30px;
  }

  section.home-slider-bg .carousel .carousel-item .carousel-caption h3 {
    text-align: center;
  }

  section.home-slider-bg .carousel .carousel-indicators {
    bottom: -40px;
  }

  section.dna-bg a.purple-btn {
    margin-bottom: 50px;
  }

  .inner-banner {
    position: relative;
  }

  .inner-banner .container {
    position: relative;
    z-index: 10;
  }

  .inner-banner:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.3);
    z-index: 0;
  }

  .nav-tabs .nav-link {
    font-size: 17px;
    padding: 10px;
  }

  .why-us ul li {
    width: 50%;
  }

  .our-values .our-values-content h3 svg {
    display: none;
  }

  h3 {
    font-size: 30px;
    line-height: 46px;
  }

  .bg-dot:before {
    display: none;
  }

  .bg-dot .pad-top-70 {
    padding-top: 0px !important;
  }

  .text-div p {
    margin-bottom: 50px;
  }

  .dna-bg {
    background-size: 100px auto;
  }

  .package-slides .slick-prev {
    left: -15px;
    width: 30px;
    height: 30px;
  }

  .package-slides .slick-next {
    right: -15px;
    width: 30px;
    height: 30px;
  }

  .package-slides .slick-next::before,
  .package-slides .slick-prev::before {
    width: 30px;
    height: 30px;
  }

  .teams .about-image {
    margin: 20px 20px 40px 20px;
  }

  .teams-card h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .modal .modal-title h4 {
    font-size: 20px;
    padding-right: 50px;
  }

  .modal .modal-header .btn-close {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    padding: 5px;
  }

  .modal .modal-header .btn-close:before {
    width: 30px;
    font-size: 24px;
  }

  span.filter-by-text {
    display: none;
  }
}

@media (max-width: 575px) {
  .teams-card {
    margin: 20px;
  }
}



/*  Dev css start */

.common-loading {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.empty-pack {
  width: 100% !important;
  text-align: center;
  padding: 150px;
}


.ant-select {
  width: 100%;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
  width: 100% !important;
  line-height: 50px;
  padding: 0px 10px;
  border-radius: 5px;
  outline: none !important;
  box-shadow: none !important;
}

.ant-select-selection-item {
  margin-top: 10px;
}

.ant-select-selection-placeholder {
  margin-top: 10px;
  color: #000000;
}

.ant-message {
  z-index: 9999 !important;
}

.txt-center {
  text-align: center;
}

.line {
  color: #ee7427 !important;
  padding-left: 65px !important;
  padding-top: 15px !important;
}

.line:hover {
  color: #000 !important;
}

.ant-message {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  padding-right: 10px !important;
}

.table-center {
  text-align: center;
  padding-top: 140px;
  padding-bottom: 140px;
}

.ant-select-multiple .ant-select-selection-placeholder {
  top: 15px !important;
  left: 20px !important;
}

/*  Dev css end */