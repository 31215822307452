footer {
  background: #603085;
  width: 100%;
  padding: 50px 0;
  position: relative;
}

.footer-socials {
  margin: 0;
  padding: 0;
  display: flex;
}

.footer-socials li {
  list-style: none;
  padding-right: 10px;
}

.footer-socials li a {
  list-style: none;
  border-radius: 50%;
  background: #fff;
  color: #603085;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  text-align: center;
  transition: all 0.5s ease;
}

.footer-socials li a:hover {
  color: #fff;
  background: #ee7427;
}

footer p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

footer p.icons {
  padding-left: 30px;
  position: relative;
}

footer p.icons a {
  color: #fff;
}

footer p.icons svg {
  position: absolute;
  left: 0;
  top: 0;
}

footer h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

.footer-column ul {
  margin: 0;
  padding: 0;
}

.footer-column ul li {
  list-style: none;
  padding: 3px 0;
  margin: 0;
}

.footer-column ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  transition: all 0.5s ease;
}

.footer-column ul li a:hover {
  color: #ee7427;
  transition: all 0.5s ease;
}

.footer-copyright {
  padding-top: 20px;
}

.footer-copyright p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.footer-call-no {
  background: #ee7427;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 17px 60px 15px 0px;
  text-align: right;
}

.footer-call-no:before {
  background: url(assets/images/callfooter.png) no-repeat;
  position: absolute;
  content: "";
  width: 115px;
  height: 84px;
  left: -115px;
  top: -1px;
  background-size: cover;
}

.footer-call-no p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 0px;
  text-align: right;
  color: #ffffff;
}

.footer-call-no p b {
  font-size: 20px;
  font-weight: 500;
}

.footer-call-no p b a {
  color: #fff;
}

.footer-bottom {
  border-top: 1px solid #ee7427;
  background: #603085;
  padding: 20px;
}

.footer-bottom-text {
  font-size: 14px;
}

ul.right-fixed {
  position: fixed;
  right: 0px;
  top: 30%;
  padding: 0px 7px;
  border-radius: 7px 0px 0px 7px;
  background: #603085;
  z-index: 999;
}

ul.right-fixed li {
  list-style: none;
  display: block;
  z-index: 999;
  position: relative;
}

ul.right-fixed li a {
  padding: 15px 7px;
  color: #fff;
  text-align: center;
  font-size: 30px;
  display: flex;
  align-items: center;
  z-index: 999;
  border-radius: 3px;
  position: relative;
  justify-content: center;
  transition: all 0.3s ease;
}

ul.right-fixed li a svg,
ul.right-fixed li a img {
  transform: scale(1);
  transition: all 0.3s ease;
}

ul.right-fixed li a:hover svg,
ul.right-fixed li a:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

ul.right-fixed li a span {
  position: absolute;
  right: 70px;
  padding: 10px 15px;
  background: #ee7427;
  color: #fff;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 5px;
  opacity: 0;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  visibility: hidden;
  transition: all 0.3s ease;
}

ul.right-fixed li a:hover span {
  right: 55px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

ul.right-fixed li a span:before {
  position: absolute;
  right: -7px;
  top: 10px;
  content: "";
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #ee7427;
}

ul.right-fixed li:nth-child(2) {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

ul.right-fixed li:nth-child(3) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 991px) {

  .pe-80 {
    padding-right: 0px !important;
  }

  footer h5 {
    margin-top: 50px;
  }

  .footer-call-no {
    position: fixed;
    padding: 10px 20px 10px 0px;
    z-index: 999;
  }

  .footer-bottom-text {
    padding-bottom: 80px;
  }

  .footer-call-no p {
    margin-bottom: 0px;
  }

  .footer-call-no {
    bottom: 0px;
  }
}

@media (max-width: 600px) {
  .footer-bottom-text {
    padding-bottom: 130px;
  }

  .footer-call-no {
    bottom: 60px;
  }

  ul.right-fixed {
    top: inherit;
    bottom: 0;
    width: 100%;
    border-radius: 0px;
    padding: 0px;
    border-top: 2px solid #ee7427;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul.right-fixed li {
    width: 33.33%;
    display: block;
  }

  ul.right-fixed li a {
    padding: 0px 5px 5px 5px;
    display: block;
  }

  ul.right-fixed li a span {
    right: inherit;
    left: inherit;
    top: inherit;
    position: relative;
    bottom: inherit;
    background: transparent;
    color: #fff;
    padding: 0px;
    box-shadow: none;
    width: 100%;
    opacity: 1;
    display: block;
    white-space: inherit;
    visibility: visible;
    font-size: 12px;
  }

  ul.right-fixed li a:hover span {
    right: inherit;
    left: inherit;
    top: inherit;
    position: relative;
    bottom: inherit;
  }

  ul.right-fixed li a span:before {
    display: none;
  }

  ul.right-fixed li:nth-child(2) {
    border-top: none;
    border-bottom: none;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  ul.right-fixed li:nth-child(3) {
    border-top: none;
    border-bottom: none;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
}

@media (max-width: 400px) {
  ul.right-fixed li a span {
    font-size: 10px;
  }
}